import React from 'react';
import '../styles/Contact.css'; // Import your CSS file for styling
import { BiTwitter, BiFacebook, BiInstagram, BiLinkedin } from 'react-icons/bi';
import { FaTwitter, FaFacebook, FaInstagram,FaLinkedinIn } from 'react-icons/fa'; // Import the specific icons you need

const Contact = () => {
    return (
        <section id="contact" className="contact">
            <div className="container">

                <div className="section-title" data-aos="fade-up">
                    <h2>Contact Us</h2>
                </div>

                <div className="contact-row">

                    <div className="contact-col" data-aos="fade-up" data-aos-delay="100">
                        <div className="contact-about">
                            <h3>WhyMyCode</h3>
                            <p>Cras fermentum odio eu feugiat. Justo eget magna fermentum iaculis eu non diam phasellus. Scelerisque felis imperdiet proin fermentum leo. Amet volutpat consequat mauris nunc congue.</p>
                            <div className="social-links">
                                <a href="#" className="twitter"><FaTwitter /></a>
                                <a href="#" className="facebook"><FaFacebook /></a>
                                <a href="#" className="instagram"><FaInstagram /></a>
                                <a href="https://www.linkedin.com/in/whymycode-ai-0a899826a/" className="linkedin"><FaLinkedinIn /></a>

                            </div>
                        </div>
                    </div>

                    <div className="contact-col mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
                        <div className="info">
                            <div>
                                <i className="ri-map-pin-line"></i>
                                <p>A108 Adam Street<br />New York, NY 535022</p>
                            </div>

                            <div>
                                <i className="ri-mail-send-line"></i>
                                <p>info@example.com</p>
                            </div>

                            <div>
                                <i className="ri-phone-line"></i>
                                <p>+1 5589 55488 55s</p>
                            </div>
                        </div>
                    </div>

                    <div className="contact-col" data-aos="fade-up" data-aos-delay="300">
                        <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                            <div className="form-group">
                                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center"><button type="submit">Send Message</button></div>
                        </form>
                    </div>

                </div>

            </div>
        </section>
    );
};

export default Contact;
