import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Home from './screens/Home';
import Products from './screens/Products';
import Services from './screens/Services';
import About from './screens/About';
import Contact from './screens/Contact';
import Footer from './screens/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Services />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
