import React from 'react';
import '../styles/Services.css'; // Import your CSS file for styling

const Services = () => {
    return (
        <section id="services" className="services-container">
            <div className="container">

                <div className="section-title" data-aos="fade-up">
                    <h2>Services</h2>
                    <p>Magnam dolores commodi suscipit eius consequatur ex aliquid fug</p>
                </div>

                <div className="services-list">
                    <div className="service-item" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon"><i className="bx bxl-dribbble"></i></div>
                        <h4 className="title"><a href="">Lorem Ipsum</a></h4>
                        <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
                    </div>

                    <div className="service-item" data-aos="fade-up" data-aos-delay="200">
                        <div className="icon"><i className="bx bx-file"></i></div>
                        <h4 className="title"><a href="">Sed ut perspiciatis</a></h4>
                        <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
                    </div>

                    <div className="service-item" data-aos="fade-up" data-aos-delay="300">
                        <div className="icon"><i className="bx bx-tachometer"></i></div>
                        <h4 className="title"><a href="">Magni Dolores</a></h4>
                        <p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
                    </div>

                    <div className="service-item" data-aos="fade-up" data-aos-delay="400">
                        <div className="icon"><i className="bx bx-world"></i></div>
                        <h4 className="title"><a href="">Nemo Enim</a></h4>
                        <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Services;
