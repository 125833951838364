import React from 'react';
import '../styles/Home.css';

const Home = () => {
  return (
    <div className='home-container'>
      <div className='your-col'>
        <h1 data-aos='fade-up'>Grow your business with WhyMyCode</h1>
        <h2 data-aos='fade-up' data-aos-delay='400'>
          We are team of talented developers making AI based products
        </h2>
        <div data-aos='fade-up' data-aos-delay='800'>
          <a href='#about' className='btn-get-started scrollto'>
            Get Started
          </a>
        </div>
      </div>
      <div>
        <img
          src={require('../assets/images/png/hero-img.png')}
          alt='Hero'
          className='img-fluid'
        />
      </div>
    </div>
  );
};

export default Home;
